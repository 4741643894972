import ng from 'angular';
import { IStateService } from 'angular-ui-router';

export function acpRoutePageTitleDirective(
  $state: IStateService,
  nsConfig: any
): ng.IDirective {
  'ngInject';
  return {
    restrict: 'A',
    controller: ($scope: ng.IScope, $element: JQLite, $sce: ng.ISCEService) => {
      'ngInject';
      const defaultValue = nsConfig.get('appTitle');
      $scope.$watch(
        () => {
          return $state.$current.locals.globals.pageTitle || defaultValue;
        },
        (newValue: string) => {
          // In order to display brand-name yml values that contain register symbols eg. Netspend<sup>&reg;</sup>
          const pageTitle = newValue.replace(/<sup>|<\/sup>/g, ''); // dont display sup tags
          $element.html($sce.getTrustedHtml(pageTitle)); // set as trusted html
        }
      );
    }
  };
}
const ngModule = ng
  .module('acp.shared.directives.routePageTitleDirective', [
    'ui.router',
    'netspend.ui.utils.core'
  ])
  .directive('acpRoutePageTitle', acpRoutePageTitleDirective);

export default ngModule;
